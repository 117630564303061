import { Menu, SubMenu, subMenuItemList } from "./Menu";

export enum UIService {
  SHOW_RECHARGES = "SHOW_RECHARGES",
  SHOW_OTHER_TRANSACTIONS = "SHOW_OTHER_TRANSACTIONS",
  RATES = "RATES",
  VIEW_CDR = "VIEW_CDR",
  VIEW_CSR = "VIEW_CSR",
  IP_TRUNK_LIST = "IP_TRUNK_LIST",
  USER_TRUNK_LIST = "USER_TRUNK_LIST",
  VIRTUAL_NUMBER_LIST = "VIRTUAL_NUMBER_LIST",
  PURCHASE_VIRTUAL_NUMBER = "PURCHASE_VIRTUAL_NUMBER",
  CREATE_EXTENSION = "CREATE_EXTENSION",
  ALL_TRANSACTIONS = "ALL_TRANSACTIONS",
  INVOICES = "INVOICES",
  ORDER_HISTORY = "ORDER_HISTORY"
}

export type UIServiceItem = {
  uiService: UIService;
  submenu: SubMenu;
};

export const uiServiceItemList: UIServiceItem[] = [
  {
    uiService: UIService.SHOW_RECHARGES,
    submenu: SubMenu.TRANSACTIONS,
  },
  {
    uiService: UIService.SHOW_OTHER_TRANSACTIONS,
    submenu: SubMenu.OTHER_CHARGES,
  },
  {
    uiService: UIService.RATES,
    submenu: SubMenu.RATES,
  },
  {
    uiService: UIService.VIEW_CDR,
    submenu: SubMenu.CDR,
  },
  {
    uiService: UIService.VIEW_CSR,
    submenu: SubMenu.CSR,
  },
  {
    uiService: UIService.IP_TRUNK_LIST,
    submenu: SubMenu.IP_TRUNK,
  },
  {
    uiService: UIService.USER_TRUNK_LIST,
    submenu: SubMenu.USER_TRUNK,
  },
  {
    uiService: UIService.VIRTUAL_NUMBER_LIST,
    submenu: SubMenu.VIRTUAL_NUMBER,
  },
  {
    uiService: UIService.PURCHASE_VIRTUAL_NUMBER,
    submenu: SubMenu.PURCHASE_VIRTUAL_NUMBER,
  },
  {
    uiService: UIService.CREATE_EXTENSION,
    submenu: SubMenu.CREATE_EXTENSION,
  },
  {
    uiService: UIService.ALL_TRANSACTIONS,
    submenu: SubMenu.ALL_TRANSACTIONS,
  },
  {
    uiService: UIService.INVOICES,
    submenu: SubMenu.INVOICES,
  },
  {
    uiService: UIService.ORDER_HISTORY,
    submenu: SubMenu.ORDER_HISTORY,
  },
];

export const getMenu = (service: UIService) => {
  const subMenuItem = subMenuItemList?.find(
    (subMenu) => subMenu.title == getSubMenu(service)
  );
  return subMenuItem ? subMenuItem.parentMenuItem : Menu.UNKNOWN;
};

export const getSubMenu = (service: UIService) => {
  const currentService = uiServiceItemList.find(
    (serviceItem) => serviceItem.uiService === service
  );
  return currentService ? currentService.submenu : SubMenu.UNKNOWN;
};
