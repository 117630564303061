import React from "react";
import { NumberRedirection } from "../../../../models/response/GetExtensionResponse";
import styles from "./VirtualNumberRedirectionDetails.module.css";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import Dialog, { Size } from "../../../business/Dialog/Dialog";

type VirtualNumberRedirectionsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  extensionUsername: string;
  redirections: NumberRedirection[];
};

const VirtualNumberRedirectionDetails: React.FC<VirtualNumberRedirectionsDialogProps> = ({
                                                                                           isOpen,
                                                                                           onClose,
                                                                                           extensionUsername,
                                                                                           redirections
                                                                                         }) => {
  if (!isOpen) return null;

  const renderRedirectionsContent = () => (
    <div className={styles.redirectionDetailsContainer}>
      <h3 className={styles.redirectionDetailsHeader}>{`Redirected Numbers for ${extensionUsername}`}</h3>

      <div className={styles.redirectionDetailsContent}>
        {redirections.length === 0 ? (
          <p className={styles.noRedirectionMessage}>
            No virtual numbers configured for this extension.
          </p>
        ) : (
          <div className={styles.redirectionDetailsTable}>
            <div className={styles.tableHeader}>
              <span className={styles.tableHeaderLabel}>Sr. No</span>
              <span className={styles.tableHeaderLabel}>Redirected Number</span>
            </div>
            <div className={styles.redirectionDetails}>
              {redirections.map((redirection, index) => (
                <div key={redirection.id} className={styles.redirectionDetailsRow}>
                  <span className={styles.redirectionDetailsValue}>{index + 1}</span>
                  <span className={styles.redirectionDetailsValue}>{redirection.virtualNumber}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={styles.buttonContainer}>
        <SkyButton
          onClick={onClose}
          size={ButtonSize.SMALL}
          testId="redirections-close-button"
          text="Close"
        />
      </div>
    </div>
  );


  return (
    <Dialog
      header="Redirected Numbers for Extension"
      renderer={renderRedirectionsContent}
      size={Size.SM}
      testId="virtual-number-redirections-dialog"
    />
  );
};

export default VirtualNumberRedirectionDetails;