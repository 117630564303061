import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import styles from "./InvoiceScreen.module.css";
import SkyGrid from "../../components/base/SkyGrid/skyGrid";
import {fetchAccountSummary, getInvoice, processWalletPayment} from "../../api/endpoint";
import {notyf} from "../../notyf";
import {FetchInvoiceResponse} from "../../models/response/FetchInvoiceResponse";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import moment from "moment/moment";
import {UserDetailsContext} from "../../contextApi/UserDetailsContext/UserDetailsContext";
import SkyTooltip from "../../components/base/SkyTooltip/SkyTooltip";
import InvoiceDownload from "./InvoiceDownload/InvoiceDownload";
import Dialog, {Size} from "../../components/business/Dialog/Dialog";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import SkyButton, {ButtonSize, ButtonType} from "../../components/base/SkyButton/SkyButton";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {FeatureToggleContext} from "../../contextApi/FeatureToggleContext/FeatureToggleContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InvoiceView from "./InvoiceView/InvoiceView";

const InvoiceScreen = () => {
  const {isPayInvoiceEnabled} = useContext(FeatureToggleContext);
  const columns = [
    "Invoice No.",
    "Amount",
    "Status",
    "Date",
    ...(isPayInvoiceEnabled ? ["Payment"] : []),
    "Options"
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<FetchInvoiceResponse[]>([]);
  const {accessToken} = useContext(AuthContext);
  const {user} = useContext(UserDetailsContext);
  const [balance, setBalance] = useState<number | null>(null);
  const [showInsufficientBalanceDialog, setShowInsufficientBalanceDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showInvoicePaymentDetail, setShowInvoicePaymentDetail] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<FetchInvoiceResponse | null>(null);
  const [viewInvoice, setViewInvoice] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccountSummary(accessToken || "")
      .then((response) => {
        setBalance(response.balance);
      })
      .catch(() => {
        notyf.error("Unable to fetch account balance");
      });
  }, [accessToken]);

  const fetchInvoices = useCallback(async () => {
    setIsLoading(true);
    getInvoice(accessToken || "")
      .then((response) => {
        setInvoices(response);
      })
      .catch(() => {
        notyf.error("Unable to fetch invoice data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessToken]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  const formatDate = (date: string) => {
    const parsedDate = moment(date, "DD/MM/YYYY at HH:mm");
    return parsedDate.format("Do MMM, YYYY");
  };

  const handlePayClick = (invoice: FetchInvoiceResponse) => {
    setSelectedInvoice(invoice);
    if (!balance || balance < invoice.amount) {
      setShowInsufficientBalanceDialog(true);
    } else {
      setShowInvoicePaymentDetail(true);
    }
  }

  const handleWalletPaymentConfirm = async () => {
    if (!selectedInvoice) return;
    setIsLoading(true);

    try {
      await processWalletPayment(accessToken ? accessToken : "", selectedInvoice.id);
      notyf.success("Wallet payment processed successfully.");
      setShowInvoicePaymentDetail(false);
      setShowSuccessDialog(true);
      await fetchInvoices();
    } catch (error: any) {
      notyf.error(
        error?.response?.status === 402
          ? "Insufficient balance in your wallet."
          : "Unable to process wallet payment."
      );
    } finally {
      setIsLoading(false);
    }
  }

  const InvoicePayDialog = () => {
    return (
      <div className={styles.previewContainer}>
        <h3 className={styles.previewHeader} data-testid="invoice-preview-header">Invoice Payment Details</h3>

        <div className={styles.previewContent}>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="invoice-number-label">Invoice Number:</span>
            <span className={styles.previewValuePurple}
                  data-testid="invoice-number-value">{selectedInvoice?.number}</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="amount-label">Amount to Pay:</span>
            <span className={styles.previewValuePurple} data-testid="amount-value">${selectedInvoice?.amount}</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="payment-method-label">Payment Method:</span>
            <span className={styles.previewValuePurple} data-testid="payment-method-value">Wallet</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="date-label">Date:</span>
            <span className={styles.previewValuePurple}
                  data-testid="date-value">{formatDate(selectedInvoice?.createdAt!)}</span>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          {isLoading ? (
            <DotLoader/>
          ) : (
            <>
              <SkyButton
                text="Pay Now"
                size={ButtonSize.SMALL}
                onClick={handleWalletPaymentConfirm}
                testId="confirm-wallet-payment-button"
              />
              <SkyButton
                text="Cancel"
                size={ButtonSize.SMALL}
                type={ButtonType.CANCEL}
                onClick={() => setShowInvoicePaymentDetail(false)}
                testId="cancel-wallet-payment-button"
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const InsufficientBalanceDialog = () => {
    return (
      <div className={classNames(styles.dialogOuterContainer, styles.dialogContainer)}>
        <span className={styles.dialogText} data-testid="insufficient-balance-text">
        Your wallet balance (${balance?.toFixed(2)}) is insufficient to pay invoice {selectedInvoice?.number} (${selectedInvoice?.amount}).
        Please add funds to your wallet to proceed with the payment.
      </span>
        <div className={styles.buttonContainer}>
          <SkyButton
            onClick={() => navigate('/recharge')}
            size={ButtonSize.SMALL}
            testId="add-funds-button"
            text="Add Funds"
          />
          <SkyButton
            onClick={() => setShowInsufficientBalanceDialog(false)}
            size={ButtonSize.SMALL}
            type={ButtonType.CANCEL}
            testId="close-button"
            text="Close"
          />
        </div>
      </div>
    );
  };

  const PaymentSuccessDialog = () => {
    return (
      <div className={classNames(styles.dialogOuterContainer, styles.dialogContainer)}>
        <span className={styles.dialogText} data-testid="success-text">
          Payment for invoice {selectedInvoice?.number} has been processed successfully.
          <br/>
          Amount paid:{" "}
          <span className={styles.bold}>
            ${parseFloat(String(selectedInvoice?.amount)).toFixed(2)}
          </span>
        </span>
        <div className={styles.dialogButtonContainer}>
          <SkyButton
            onClick={() => setShowSuccessDialog(false)}
            size={ButtonSize.SMALL}
            testId="ok-button"
            text="OK"
          />
        </div>
      </div>
    );
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case "UNPAID" :
        return styles.unpaid;
      case "PAID" :
        return styles.paid;
      default:
        return;
    }
  }

  return (
    <div className={styles.container} data-testid="invoice-sheet">
      {showInvoicePaymentDetail && (
        <Dialog
          testId="invoice-preview-dialog"
          size={Size.SM}
          header="Invoice Payment Details"
          renderer={() => <InvoicePayDialog/>}
        />
      )}

      {showInsufficientBalanceDialog && (
        <Dialog
          testId="insufficient-balance-dialog"
          size={Size.SM}
          header="Insufficient Balance"
          renderer={() => <InsufficientBalanceDialog/>}
        />
      )}

      {showSuccessDialog && (
        <Dialog
          testId="payment-success-dialog"
          size={Size.SM}
          header="Payment Successful"
          renderer={() => <PaymentSuccessDialog/>}
        />
      )}

      {viewInvoice && (
        <Dialog
          testId=""
          size={Size.XL}
          header="Invoice"
          top={window.innerWidth <= 720 ? "0px" : "70px"}
          renderer={() =>
            <InvoiceView
              invoiceId={selectedInvoice?.id || ""}
              onClose={() => setViewInvoice(false)}
            />
        }
        />
      )}

      <div className={styles.tableContainer}>
        <SkyGrid
          columns={columns}
          isLoading={isLoading}
          renderer={invoices.map((obj) => [
            () => <div>
              {obj.number}
            </div>,
            () => <div>
              ${parseFloat(String(obj.amount)).toFixed(2)}
            </div>,
            () => <div className={classNames(styles.status, getStatusBadge(obj.status))}>
              {obj.status}
            </div>,
            () => <div>
              {formatDate(obj.createdAt)}
            </div>,
            ...(isPayInvoiceEnabled
              ? [() => <div>
                <button
                  className={styles.payButton}
                  onClick={() => handlePayClick(obj)}
                  disabled={obj.status === "PAID" || obj.status === "PENDING" || user?.status !== "ACTIVE"}
                >
                  {obj.status === "PAID"
                    ? "Paid"
                    : obj.status === "PENDING"
                      ? "Pending"
                      : "Pay Now"
                  }
                </button>
              </div>]
              : []),
            () => <div className={styles.options}>
              <SkyTooltip
                tooltipText={`View ${obj.number} Invoice`}
              >
                <VisibilityIcon
                  className={styles.icon}
                  data-testid="invoice-view"
                  onClick={() => {
                    setSelectedInvoice(obj);
                    setViewInvoice(true)
                  }}
                />
              </SkyTooltip>
              <SkyTooltip
                tooltipText={`Download ${obj.number} Invoice`}
              >
                <div><InvoiceDownload invoice={obj}/></div>
              </SkyTooltip>
            </div>,
          ])}
        />
      </div>
    </div>
  )
}
export default InvoiceScreen
