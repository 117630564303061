import React, {useEffect, useState} from 'react'
import styles from "./PdfViewer.module.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

type Props = {
  url:string
}

const PdfViewer = (props:Props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  },[])

  if(!mounted) return null;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className={styles.pdfContainer}>
        <Viewer
          fileUrl={props.url}
        />
      </div>
    </Worker>
  )
}
export default PdfViewer
