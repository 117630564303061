export const validRoutes= [
  "/",
  "/login",
  "/register",
  "/signup",
  "/sipTrunk/verifySuccess",
  "/privacy-policy",
  "/term-of-use",
  "/dashboard",
  "/recharge",
  "/profile",
  "/checkout",
  "/recharge/confirmation",
  "/sipTrunk",
  "/pricing",
  "/numbers",
  "/report",
  "/numbers/virtualNumbers",
  "/numbers/purchaseVirtualNumber",
  "/transactions/recharge",
  "/transactions/others",
  "/pricing/rates",
  "/report/cdr",
  "/report/csr",
  "/sipTrunk/ipTrunk",
  "/sipTrunk/userTrunk",
  "/cloudTelephony",
  "/cloudTelephony/extension",
  "/report/orderHistory"
]