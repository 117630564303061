import React, {useContext, useState} from 'react'
import waitingImage from "../../../images/downloader.gif";
import downloadIcon from "../../../icons/download.png"
import classNames from "classnames";
import styles from "./InvoiceDownload.module.css";
import {FetchInvoiceResponse} from "../../../models/response/FetchInvoiceResponse";
import {getInvoiceByInvoiceId} from "../../../api/endpoint";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {notyf} from "../../../notyf";
import {decodeBase64ToBlob} from "../../../domains/decodeBase64ToBlob";

type Props = {
  invoice: FetchInvoiceResponse;
};

const InvoiceDownload = (props: Props) => {
  const [isDownloadInProgress, setIsDownloadInProgress] =
    useState<boolean>(false);
  const {accessToken} = useContext(AuthContext);

  const downloadPDF = () => {
    setIsDownloadInProgress(true);
    getInvoiceByInvoiceId(accessToken || '', props.invoice.id)
      .then(pdf => {
          const blob = decodeBase64ToBlob(pdf);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${props.invoice.number}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch(() => {
        notyf.error("Error while downloading invoice data");
      })
      .finally(() => {
        setIsDownloadInProgress(false);
      });
  }

  return (
    <>
      {isDownloadInProgress ? (
        <div data-testid="download-icon-waiting">
          <img
            src={waitingImage}
            className={styles.downloadIcon}
            alt="download waiting icon"
          />
        </div>
      ) : (
        <div
          onClick={() => downloadPDF()}
          data-testid="download-icon"
        >
          <img
            src={downloadIcon}
            className={classNames(styles.downloadIcon, styles.clickable)}
            alt="clickable-download-icon"
          />
        </div>
      )}
    </>
  )
}
export default InvoiceDownload
