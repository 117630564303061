import { createContext, useEffect, useState } from "react";
import { fetchFeatureTogglesList } from "../../api/endpoint";
import { notyf } from "../../notyf";

type FeatureToggleContextDataType = {
    isProdEnv: boolean;
    setIsProdEnv: (isProdEnv: boolean) => void;
    isNewLoginFlow: boolean;
    setIsNewLoginFlow: (isNewLoginFlow: boolean) => void;
    isDIDFlowEnabled: boolean;
    setIsDIDFlowEnabled: (isDIDFlowEnabled: boolean) => void;
    isCopyIpEnabled: boolean;
    setIsCopyIpEnabled: (isCopyIpEnabled: boolean) => void;
    isSignUpEnabled: boolean;
    setIsSignUpEnabled: (isSignUpEnabled: boolean) => void;
    isResendOTPEnabled: boolean;
    setIsResendOTPEnabled: (isResendOTPEnabled: boolean) => void;
    isPayInvoiceEnabled: boolean;
    setIsPayInvoiceEnabled: (isPayInvoiceEnabled: boolean) => void;
    isAllTransactionEnabled: boolean;
    setIsAllTransactionEnabled: (isAllTransactionEnabled: boolean) => void;
    isInvoiceEnabled: boolean;
    setIsInvoiceEnabled: (isInvoiceEnabled: boolean) => void;
};

export const FeatureToggleContext = createContext<FeatureToggleContextDataType>(
    {
        isProdEnv: true,
        setIsProdEnv: () => {},
        isNewLoginFlow: true,
        setIsNewLoginFlow: () => {},
        isDIDFlowEnabled: false,
        setIsDIDFlowEnabled: () => {},
        isCopyIpEnabled: false,
        setIsCopyIpEnabled: () => {},
        isSignUpEnabled: false,
        setIsSignUpEnabled: () => {},
        isResendOTPEnabled: false,
        setIsResendOTPEnabled: () => {},
        isPayInvoiceEnabled: false,
        setIsPayInvoiceEnabled: () => {},
        isAllTransactionEnabled: false,
        setIsAllTransactionEnabled: () => {},
        isInvoiceEnabled: false,
        setIsInvoiceEnabled: () => {},
    }
);

export const FeatureToggleContextProvider = ({ children }) => {
    useEffect(() => {
        fetchFeatureTogglesList()
            .then((featureToggleList) => {
                featureToggleList.forEach((ft) => {
                    if (ft.name === "IS_PROD_ENV") setIsProdEnv(ft.value);
                    if (ft.name === "ENABLE_NEW_LOGIN_FLOW") setIsNewLoginFlow(ft.value);
                    if (ft.name === "ENABLE_DID_FLOW") setIsDIDFlowEnabled(ft.value);
                    if(ft.name === "ENABLE_COPY_IP") setIsCopyIpEnabled(ft.value);
                    if(ft.name === "ENABLE_SIGN_UP") setIsSignUpEnabled(ft.value);
                    if (ft.name === "RESEND_OTP") setIsResendOTPEnabled(ft.value);
                    if (ft.name === "PAY_INVOICE") setIsPayInvoiceEnabled(ft.value);
                    if (ft.name === "SHOW_ALL_TRANSACTIONS") setIsAllTransactionEnabled(ft.value);
                    if (ft.name === "SHOW_INVOICES") setIsInvoiceEnabled(ft.value);
                });
            })
            .catch(() => {
                notyf.error("Unable to fetch environment details");
            });
    });

    const [isProdEnv, setIsProdEnv] = useState<boolean>(true);
    const [isNewLoginFlow, setIsNewLoginFlow] = useState<boolean>(false);
    const [isDIDFlowEnabled, setIsDIDFlowEnabled] = useState<boolean>(false);
    const [isCopyIpEnabled, setIsCopyIpEnabled ] = useState(false);
    const [isSignUpEnabled, setIsSignUpEnabled] = useState(false);
    const [isResendOTPEnabled, setIsResendOTPEnabled] = useState<boolean>(false);
    const  [isPayInvoiceEnabled, setIsPayInvoiceEnabled] = useState<boolean>(false);
    const  [isAllTransactionEnabled, setIsAllTransactionEnabled] = useState<boolean>(false);
    const  [isInvoiceEnabled, setIsInvoiceEnabled] = useState<boolean>(false);

    return (
        <FeatureToggleContext.Provider
            value={{
                isProdEnv,
                setIsProdEnv,
                isNewLoginFlow,
                setIsNewLoginFlow,
                isDIDFlowEnabled,
                setIsDIDFlowEnabled,
                isCopyIpEnabled,
                setIsCopyIpEnabled,
                isSignUpEnabled,
                setIsSignUpEnabled,
                isResendOTPEnabled,
                setIsResendOTPEnabled,
                isPayInvoiceEnabled,
                setIsPayInvoiceEnabled,
                isAllTransactionEnabled,
                setIsAllTransactionEnabled,
                isInvoiceEnabled,
                setIsInvoiceEnabled
            }}
        >
            {children}
        </FeatureToggleContext.Provider>
    );
};
