import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import {User} from "../../domains/User";
import {fetchUserProfile} from "../../api/endpoint";
import {UserDetailsResponse} from "../../models/response/UserDetaiilsResponse";
import {notyf} from "../../notyf";
import styles from "./ProfileScreen.module.css";

const ProfileScreen: FunctionComponent = () => {
  const [user, setUser] = useState<User | null>(null);
  const {accessToken} = useContext(AuthContext);

  useEffect(() => {
    fetchUserProfile(accessToken ? accessToken : "")
      .then((userDetailsResponse: UserDetailsResponse) => {
        setUser({
          id: userDetailsResponse.id,
          account: userDetailsResponse.account,
          activeTime: userDetailsResponse.activeTime,
          address: userDetailsResponse.address,
          postCode: userDetailsResponse.postCode,
          linkMan: userDetailsResponse.linkMan,
          telephone: userDetailsResponse.telephone,
          email: userDetailsResponse.email,
          companyName: userDetailsResponse.companyName,
          status: userDetailsResponse.status,
          kycRejectionReason: userDetailsResponse.kycRejectionReason
        });
      })
      .catch(() => {
        notyf.error("Unable to fetch account data");
      });
  }, []);

  return (
    <div data-testid="profile-page" className={styles.profileContainer}>
      <div className={styles.pageHeader}> Skytel Digicom</div>
      <div className={styles.profileDetailsContainer}>
        <div className={styles.highlightContainer}>
          <div className={styles.highlightHeaderContainer}>
            <div className={styles.nameInitialContainer}>
              <h2>{user?.companyName.substring(0, 1).toUpperCase()}</h2>
            </div>
            <div className={styles.userDetailContainer}>
              <span className={styles.popUpName}>{user?.companyName}</span>
              <span className={styles.popUpId}>{user?.email}</span>
            </div>
          </div>
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.header}> Account Details</div>
          <div className={styles.detailListContainer}>
            <table className={styles.details}>
              <tbody>
              <tr>
                <td> Account :</td>
                <td> {user?.account} </td>
              </tr>
              <tr>
                <td> Name :</td>
                <td> {user?.companyName} </td>
              </tr>
              <tr>
                <td> Email ID :</td>
                <td> {user?.email} </td>
              </tr>
              <tr>
                <td> Address :</td>
                <td> {user?.address} </td>
              </tr>
              <tr>
                <td> Postcode :</td>
                <td> {user?.postCode} </td>
              </tr>
              <tr>
                <td> Key Contact Person :</td>
                <td> {user?.linkMan} </td>
              </tr>
              <tr>
                <td> Telephone :</td>
                <td> {user?.telephone} </td>
              </tr>

              <tr>
                <td> Company Name :</td>
                <td> {user?.companyName} </td>
              </tr>

              <tr>
                <td> Active Time :</td>
                <td> {user?.activeTime} </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileScreen;
