import React, { useContext, useEffect, useState } from "react";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import styles from "./UpdateExtension.module.css";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { notyf } from "../../../../notyf";
import DotLoader from "../../../business/DotLoader/DotLoader";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import classNames from "classnames";
import { GetExtensionResponse } from "../../../../models/response/GetExtensionResponse";
import { updateExtension } from "../../../../api/endpoint";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import { UpdateExtensionRequest } from "../../../../models/request/UpdateExtensionRequest";

type UpdateExtensionProps = {
  isOpen: boolean;
  onClose: () => void;
  extension: GetExtensionResponse;
  onSuccess: () => void;
};

enum SettingStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

const UpdateExtension: React.FC<UpdateExtensionProps> = ({
                                                                           isOpen,
                                                                           onClose,
                                                                           extension,
                                                                           onSuccess
                                                                         }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [voicemailStatus, setVoicemailStatus] = useState<SettingStatus>(
    extension.voicemailEnabled ? SettingStatus.ENABLED : SettingStatus.DISABLED
  );
  const [callRecordingStatus, setCallRecordingStatus] = useState<SettingStatus>(
    extension.callRecordingEnabled ? SettingStatus.ENABLED : SettingStatus.DISABLED
  );
  const [isUpdateConfirmed, setIsUpdateConfirmed] = useState(false);

  useEffect(() => {
    if (extension) {
      setVoicemailStatus(extension.voicemailEnabled ? SettingStatus.ENABLED : SettingStatus.DISABLED);
      setCallRecordingStatus(extension.callRecordingEnabled ? SettingStatus.ENABLED : SettingStatus.DISABLED);
    }
  }, [extension]);

  const handleUpdate = async () => {
    if (!accessToken) return;
    setIsLoading(true);
    try {
      const updateRequest: UpdateExtensionRequest = {
        voicemailEnabled: voicemailStatus === SettingStatus.ENABLED,
        callRecordingEnabled: callRecordingStatus === SettingStatus.ENABLED
      };

      await updateExtension(accessToken, extension.id, updateRequest);
      handleUpdateSuccess();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to update Extension";
      notyf.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSuccess = () => {
    setIsUpdateConfirmed(true);
    onSuccess();
    notyf.success("Extension updated successfully");
  };

  const handleCloseDialog = () => {
    onClose();
    setIsUpdateConfirmed(false);
  };

  if (!isOpen) return null;

  if (isUpdateConfirmed) {
    return (
      <Dialog
        size={Size.SM}
        header="Update Settings"
        renderer={() => (
          <div className={classNames(styles.settingsContainer, styles.confirmationContainer)}>
            <span className={styles.confirmationMessage} data-testid="confirmation-text">
              Extension {extension?.username} have been updated successfully.
            </span>
            <div className={styles.buttonContainer}>
              <SkyButton onClick={handleCloseDialog} size={ButtonSize.SMALL} testId="ok-button" text="Ok" />
            </div>
          </div>
        )}
      />
    );
  }

  const renderSettingsContent = () => (
    <div className={styles.settingsOuterContainer}>
      <div className={styles.settingSection}>
        <div className={styles.settingTitle}>Voicemail Settings</div>
        <div className={classNames(styles.settingOptions, styles.grayFont)}>
          <div
            onClick={() => !isLoading && setVoicemailStatus(SettingStatus.ENABLED)}
            className={`${styles.optionClickable} ${isLoading ? styles.disabled : ""}`}
            data-testid="voicemail-enable-option"
          >
            {voicemailStatus === SettingStatus.ENABLED ? (
              <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)} />
            ) : (
              <RadioButtonUnchecked />
            )}
            <span>Enable</span>
          </div>
          <div
            onClick={() => !isLoading && setVoicemailStatus(SettingStatus.DISABLED)}
            className={`${styles.optionClickable} ${isLoading ? styles.disabled : ""}`}
            data-testid="voicemail-disable-option"
          >
            {voicemailStatus === SettingStatus.DISABLED ? (
              <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)} />
            ) : (
              <RadioButtonUnchecked />
            )}
            <span>Disable</span>
          </div>
        </div>
      </div>

      <div className={styles.settingSection}>
        <div className={styles.settingTitle}>Call Recording Settings</div>
        <div className={classNames(styles.settingOptions, styles.grayFont)}>
          <div
            onClick={() => !isLoading && setCallRecordingStatus(SettingStatus.ENABLED)}
            className={`${styles.optionClickable} ${isLoading ? styles.disabled : ""}`}
            data-testid="call-recording-enable-option"
          >
            {callRecordingStatus === SettingStatus.ENABLED ? (
              <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)} />
            ) : (
              <RadioButtonUnchecked />
            )}
            <span>Enable</span>
          </div>
          <div
            onClick={() => !isLoading && setCallRecordingStatus(SettingStatus.DISABLED)}
            className={`${styles.optionClickable} ${isLoading ? styles.disabled : ""}`}
            data-testid="call-recording-disable-option"
          >
            {callRecordingStatus === SettingStatus.DISABLED ? (
              <RadioButtonChecked className={classNames(styles.selectedIcon, styles.blue)} />
            ) : (
              <RadioButtonUnchecked />
            )}
            <span>Disable</span>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className={styles.loaderContainer}>
          <DotLoader />
        </div>
      )}

      {!isLoading && (
        <div className={styles.dialogButtonContainer}>
          <SkyButton text="Update" onClick={handleUpdate} size={ButtonSize.SMALL}
                     testId="update-settings-button" disabled={isLoading} />
          <SkyButton text="Close" onClick={handleCloseDialog} size={ButtonSize.SMALL} testId="close-dialog-button"
                     disabled={isLoading} />
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      header={`Manage Extension Settings - ${extension.username}`}
      renderer={renderSettingsContent}
      size={Size.SM}
      testId="manage-extension-settings-dialog"
    />
  );
};

export default UpdateExtension;