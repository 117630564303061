import React from 'react'
import styles from "./AuthBaseScreen.module.css";
import loginImage from "../../images/login.svg";
import {Outlet, useLocation} from "react-router-dom";
import classNames from "classnames";
import registerImage from "../../images/UserWithLaptop.png";
import AuthMobileBaseScreen from "../AuthMobileBaseScreen/AuthMobileBaseScreen";
import {
  MessageSquare,
  Phone,
  CircleDollarSign,
  AtSign,
  Headset,
} from 'lucide-react';

const AuthBaseScreen = () => {
  const location = useLocation();
  const isLogin = location.pathname === '/login';

  const icons = [
    MessageSquare,
    Phone,
    CircleDollarSign,
    AtSign,
    Headset,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.animatedBackground}>
        {[...Array(60)].map((_, i) => {
          const IconComponent = icons[i % icons.length];
          const phoneCode = ['+91', '+1', '+44', '+65', '+61'][i % 5];
          return (
            <div
              key={i}
              className={`${styles.floatingIcon} ${
                i % 3 === 0
                  ? styles.large
                  : i % 3 === 1
                    ? styles.medium
                    : styles.small
              }`}
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
              }}
            >
              {i % 2 === 0 ? (
                <IconComponent size={i % 3 === 0 ? 64 : i % 3 === 1 ? 32 : 16} />
              ) : (
                <div
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {phoneCode}
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className={classNames(
        styles.wrapper,
        !isLogin ? styles.slideRight : '')}
      >
        <div className={styles.welcomeSection}>
          <div className={styles.welcomeContent}>
            <div className={styles.welcomeTitle} aria-label="Welcome">
              {'Welcome'.split('').map((letter, index) => (
                <span
                  key={index}
                  className={styles.welcomeLetter}
                  style={{animationDelay: `${index * 0.1}s`}}
                >
                  {letter}
                </span>
              ))}
            </div>
            <img
              src={!isLogin ? registerImage : loginImage}
              alt={!isLogin
                ? "Guy with laptop"
                : "A girl standing in front of a computer wearing headphone with a tab in her hand."
              }
              className={!isLogin ? styles.registerImage : styles.welcomeImage}
            />
          </div>
        </div>

        <div className={classNames(styles.formSection,
          !isLogin ? styles.leftSide : styles.rightSide
        )}>
          <Outlet/>
        </div>
      </div>
      <div className={styles.authMobileScreen}>
        <AuthMobileBaseScreen isLogin={isLogin}/>
      </div>
    </div>
  )
}
export default AuthBaseScreen