import React, { FC, useState, useContext, useEffect } from "react";
import styles from "./OrderHistory.module.css";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { fetchOrderHistory } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { OrderHistoryResponse, OrderItemDetails } from "../../../models/response/OrderHistoryResponse";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import classNames from "classnames";
import { InfoOutlined } from "@mui/icons-material";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import Dialog, { Size } from "../../business/Dialog/Dialog";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";

const OrderHistory: FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [orderHistory, setOrderHistory] = useState<OrderHistoryResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderHistoryResponse | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const columns: string[] = ["Sr. No.", "Description", "Total Amount", "Status", "Placed At", "Action"];

  const fetchOrderHistoryData = () => {
    if (!accessToken) return;
    setIsLoading(true);
    fetchOrderHistory(accessToken)
      .then((orderHistoryResponses: OrderHistoryResponse[]) => {
        setOrderHistory(orderHistoryResponses);
        setIsLoading(false);
      })
      .catch(() => {
        notyf.error("Unable to fetch order history");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderHistoryData();
  }, [accessToken]);

  const getOrderDescription = (items: OrderItemDetails[]): string => {
    return items.map(item => item.description).join(", ");
  };
  const getStatusBadge = (status: string) => {
    if (status === "INITIATED") return styles.initiated;
    if (status === "COMPLETED") return styles.completed;
    return styles.cancelled;
  };

  const handleViewDetails = (order: OrderHistoryResponse) => {
    setSelectedOrder(order);
    setShowDetailsDialog(true);
  };

  return (
    <div className={styles.container} data-testid="order-history">
      <div className={styles.tableContainer}>
        <SkyGrid
          columns={columns}
          isLoading={isLoading}
          colorBarSpan={6}
          renderer={orderHistory.map((order, index) => [
            () => (<span>{index + 1}</span>),
            () => (<span>{getOrderDescription(order.items)}</span>),
            () => (<span>$ {order.orderAmount.toFixed(2)}</span>),
            () => (
              <span className={classNames(styles.statusBadge, getStatusBadge(order.status))}>
                {order.status}
              </span>
            ),
            () => (<span>{order.placedAt.split(" at ")[0]}</span>),
            () => (
              <span className={styles.actionIcon}>
                <SkyTooltip tooltipText={"View Order detail"}>
                <InfoOutlined
                  className={styles.icon}
                  onClick={() => handleViewDetails(order)}
                  data-testid="view-details-icon"
                />
                </SkyTooltip>
              </span>
            )
          ])}
        />
        {showDetailsDialog && selectedOrder && (
          <Dialog
            testId="order-details-dialog"
            size={Size.SM}
            header="Order Details"
            renderer={() => (
              <OrderDetailsDialog
                order={selectedOrder}
                onClose={() => setShowDetailsDialog(false)}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

interface OrderDetailsDialogProps {
  order: OrderHistoryResponse;
  onClose: () => void;
}

const OrderDetailsDialog: FC<OrderDetailsDialogProps> = ({ order, onClose }) => {
  return (
    <div className={styles.orderDetailsContainer}>
      <h3 className={styles.orderDetailsHeader}>Order Details</h3>

      <div className={styles.orderDetailsContent}>
        <div className={styles.orderDetailsRow}>
          <span className={styles.orderDetailsLabel}>Order ID:</span>
          <span className={styles.orderDetailsValue}>{order.orderId}</span>
        </div>
        <div className={styles.orderDetailsRow}>
          <span className={styles.orderDetailsLabel}>Service:</span>
          <span className={styles.orderDetailsValue}>{order.serviceName}</span>
        </div>
        <div className={styles.orderDetailsRow}>
          <span className={styles.orderDetailsLabel}>Date:</span>
          <span className={styles.orderDetailsValue}>{order.placedAt}</span>
        </div>
        <div className={styles.orderDetailsRow}>
          <span className={styles.orderDetailsLabel}>Status:</span>
          <span className={styles.orderDetailsValue}>{order.status}</span>
        </div>
        <div className={styles.orderDetailsRow}>
          <span className={styles.orderDetailsLabel}>Total Amount:</span>
          <span className={styles.orderDetailsTotalAmount}>{order.currency} {order.orderAmount.toFixed(2)}</span>
        </div>
      </div>

      <div className={styles.orderItemsContainer}>
        <h4>Order Items</h4>
        <div className={styles.orderDetailsData}>
          {order.items.map((item, index) => (
            <div key={item.itemId} className={styles.orderItemDetails}>
              <div className={styles.orderDetailsRow}>
                <span className={styles.orderDetailsLabel}>Type:</span>
                <span className={styles.orderDetailsValue}>{item.itemType}</span>
              </div>
              <div className={styles.orderDetailsRow}>
                <span className={styles.orderDetailsLabel}>Item {index + 1}:</span>
                <span className={styles.orderDetailsValue}>{item.description}</span>
              </div>
              <div className={styles.orderDetailsRow}>
                <span className={styles.orderDetailsLabel}>Setup:</span>
                <span className={styles.orderDetailsValue}>{order.currency} {item.setup.toFixed(2)}</span>
              </div>
              <div className={styles.orderDetailsRow}>
                <span className={styles.orderDetailsLabel}>Rental:</span>
                <span className={styles.orderDetailsValue}>{order.currency} {item.rental.toFixed(2)}</span>
              </div>
              <div className={styles.orderDetailsRow}>
                <span className={styles.orderDetailsLabel}>Total Price:</span>
                <span className={styles.orderItemTotalPrice}>{order.currency} {item.totalPrice.toFixed(2)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.dialogButtonContainer}>
        <SkyButton
          text="Close"
          size={ButtonSize.SMALL}
          onClick={onClose}
          testId="close-order-details-button"
          disabled={false}
        />
      </div>
    </div>
  );
};

export default OrderHistory;