import React, {FC, useContext, useEffect, useState} from 'react';
import {
    KeyboardArrowUp,
    KeyboardArrowDown
} from "@mui/icons-material";
import {useQuery} from "react-query";
import classNames from "classnames";
import styles from "./RedirectNumberForm.module.css";
import errorIcon from "../../../../icons/error-emoji.png";
import {AuthContext} from "../../../../contextApi/AuthContext/authContext";
import {OTPContext} from "../../../../contextApi/OTPContext/OTPContext";
import DotLoader from "../../../business/DotLoader/DotLoader";
import {notyf} from "../../../../notyf";
import SkyButton, {ButtonSize} from "../../../base/SkyButton/SkyButton";
import SkyDropdown from "../../../base/SkyDropdown/SkyDropdown";
import {fetchExtensions, redirectVirtualNumber} from "../../../../api/endpoint";
import {isOTPRequired} from "../../../../requester";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SkyTooltip from "../../../base/SkyTooltip/SkyTooltip";
import AddExtension from "../../VirtualNumberExtension/AddExtension/AddExtension";
import { RedirectionInfo } from "../../../../models/response/VirtualNumbersResponse";
import { ExtensionStatus } from "../../../../models/response/GetExtensionResponse";

type Props = {
    virtualNumber: string;
    redirectionInfo?: RedirectionInfo | null;
    onCancel: () => void;
    onSuccess: () => void;
};

const RedirectVirtualNumberForm: FC<Props> = ({virtualNumber, onCancel, onSuccess, redirectionInfo}) => {
    const [selectedExtension, setSelectedExtension] = useState("");
    const [selectedExtensionId, setSelectedExtensionId] = useState("");
    const [isExtensionDropdownOpen, setIsExtensionDropdownOpen] = useState(false);
    const [isExtensionDialogOpen, setIsExtensionDialogOpen] = useState(false);
    const [isRedirectionSuccess, setIsRedirectionSuccess] = useState(false);
    const {accessToken} = useContext(AuthContext);
    const [isRedirectionAttempted, setIsRedirectionAttempted] = useState(false);
    const [isRedirectProcessing, setIsRedirectProcessing] = useState(false);

    const {
        setOTPRequired,
        setErrorResponse,
        isOTPVerificationSuccess,
        setIsOTPVerificationSuccess,
    } = useContext(OTPContext);

    useEffect(() => {
        if (isOTPVerificationSuccess && isRedirectionAttempted) {
            handleRedirectSuccess();
            setIsOTPVerificationSuccess(false);
            setIsRedirectionAttempted(false);
        }
    }, [isOTPVerificationSuccess, isRedirectionAttempted]);

    useEffect(() => {
        if (redirectionInfo && redirectionInfo.type === 'EXTENSION') {
            setSelectedExtension(redirectionInfo.redirectionDetails.details);
            setSelectedExtensionId(redirectionInfo.redirectionDetails.id);
        }
    }, [redirectionInfo]);

    const handleRedirectSuccess = () => {
        setIsRedirectionSuccess(true);
        notyf.success("Virtual number redirected successfully");
    };

    const handleRedirectError = (error: any) => {
        if (isOTPRequired(error)) {
            setOTPRequired(true);
            setErrorResponse(error);
        } else {
            notyf.error("Failed to redirect virtual number");
        }
    };

    const { isFetching: isExtensionsFetching, data: extensions, isError: isExtensionsFetchError, refetch: reFetchExtensions } = useQuery(
      "fetchExtensions",
      () => fetchExtensions(accessToken || ""),
      {
          enabled: isExtensionDropdownOpen,
          onError: () => notyf.error("Unable to fetch extensions"),
      }
    );

    const activeExtensions = extensions?.filter((extension) => extension.status !== ExtensionStatus.UNPROVISIONED) || [];

    const handleSubmit = async () => {
        if (!accessToken) {
            return;
        }

        setIsRedirectProcessing(true);
        setIsRedirectionAttempted(true);

        try {
            const request = {
                type: 'EXTENSION',
                destinationId: selectedExtensionId
            };

            await redirectVirtualNumber(accessToken, virtualNumber, request);
        } catch (error) {
            handleRedirectError(error);
        } finally {
            setIsRedirectProcessing(false);
        }
    };

    const getErrorMessageElement = (message: string) => {
        return (
          <div className={styles.errorMessageContainer} data-testid="error-message">
              <img src={errorIcon} alt="error icon" className={styles.errorIcon}/>
              {message}
          </div>
        );
    };

    if (isRedirectionSuccess) {
        return (
          <div className={classNames(styles.addVirtualNumberOuterContainer, styles.confirmationContainer)}>
                <span className={styles.confirmationHeader}>
                    Redirection Successful
                </span>
              <span className={styles.confirmationText} data-testid="confirmation-text">
                    Virtual number {virtualNumber} has been redirected to extension {selectedExtension}.
                </span>
              <SkyButton
                onClick={onSuccess}
                size={ButtonSize.SMALL}
                testId="ok-button"
                text="Ok"
              />
          </div>
        );
    }

    return (
      <>
          <div className={styles.addVirtualNumberOuterContainer}>
              <div className={styles.addVirtualNumberContainer} data-testid="redirect-form">
                  <div className={styles.addVirtualNumberRow}>
                      <div className={styles.addVirtualNumberCell}>
                          <div className={styles.existingExtensionContainer}>
                              <div className={styles.textLabel}>Extension Number</div>
                              <div className={styles.combinedInputContainer}>
                                  <div
                                    className={classNames(styles.roundControl, styles.ipSelector)}
                                    onClick={() => setIsExtensionDropdownOpen(!isExtensionDropdownOpen)}
                                    data-testid="extension-dropdown"
                                  >
                                      <span>{selectedExtension || "Select an extension"}</span>
                                      {isExtensionDropdownOpen ? (
                                        <KeyboardArrowUp data-testid="extension-selector-arrow-up" />
                                      ) : (
                                        <KeyboardArrowDown data-testid="extension-selector-arrow-down" />
                                      )}
                                  </div>
                                  <SkyTooltip tooltipText={"Add New Extension"}>
                                      <AddCircleOutlineIcon
                                        className={styles.addIcon}
                                        onClick={() => setIsExtensionDialogOpen(true)}
                                        data-testid={"add-extension-Icon"}
                                      />
                                  </SkyTooltip>
                              </div>
                              {isExtensionDropdownOpen && (
                                <div className={styles.dropDownContainer}>
                                    <SkyDropdown
                                      data={
                                          activeExtensions
                                            ? activeExtensions.map((ext) => ({
                                                id: ext.id,
                                                value: ext.username,
                                            }))
                                            : []
                                      }
                                      onSelect={(data) => {
                                          setSelectedExtension(data.value);
                                          setSelectedExtensionId(data.id);
                                          setIsExtensionDropdownOpen(false);
                                      }}
                                      isSearchable={true}
                                      isLoading={isExtensionsFetching}
                                    />
                                </div>
                              )}

                              {isExtensionsFetchError && getErrorMessageElement("Failed to fetch Extensions")}
                          </div>
                      </div>
                  </div>
              </div>
              {isRedirectProcessing ? (
                <div className={styles.dotLoaderContainer}>
                    <DotLoader/>
                </div>
              ) : (
                <div className={styles.addVirtualNumberDialogButtonContainer}>
                    <SkyButton
                      text="Submit"
                      onClick={handleSubmit}
                      size={ButtonSize.SMALL}
                      testId="submit-button"
                      disabled={isRedirectProcessing || !selectedExtension}
                    />
                    <SkyButton
                      text="Cancel"
                      onClick={onCancel}
                      size={ButtonSize.SMALL}
                      testId="cancel-button"
                    />
                </div>
              )}
          </div>
          {isExtensionDialogOpen && (
            <AddExtension
              isOpen={isExtensionDialogOpen}
              onClose={() => setIsExtensionDialogOpen(false)}
              onSuccess={reFetchExtensions}
            />
          )}
      </>
    );
};

export default RedirectVirtualNumberForm;