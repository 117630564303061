import React, { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import classNames from "classnames";
import styles from "../VirtualNumbersList/VirtualNumberList.module.css";
import CLIDetailsContainer from "../UserTrunkList/CLIDetailsContainer";
import { notyf } from "../../../notyf";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import { VirtualNumbersResponse } from "../../../models/response/VirtualNumbersResponse";
import { fetchVirtualNumberList } from "../../../api/endpoint";
import { FeatureToggleContext } from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import AddIcon from "@mui/icons-material/Add";
import { SwapCallsOutlined } from "@mui/icons-material";
import { SubMenu, subMenuItemList } from "../../../domains/Menu";
import { useNavigate } from "react-router-dom";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import Dialog, { Size } from "../../business/Dialog/Dialog";
import RedirectVirtualNumberForm from "./RedirectVirtualNumberForm/RedirectVirtualNumberForm";
import { UserDetailsContext } from "../../../contextApi/UserDetailsContext/UserDetailsContext";

const VirtualNumberList: FC = () => {
  const { accessToken } = useContext(AuthContext);
  const { isDIDFlowEnabled } = useContext(FeatureToggleContext);
  const { user } = useContext(UserDetailsContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [virtualNumberList, setVirtualNumberList] = useState<
    VirtualNumbersResponse[]
  >([]);
  const navigate = useNavigate();
  const [isRedirectDialogOpen, setIsRedirectDialogOpen] = useState<boolean>(false);
  const [selectedVirtualNumber, setSelectedVirtualNumber] = useState<string>("");
  const isDIDEnabled = isDIDFlowEnabled && user?.status === 'ACTIVE';

  const getCustomHeaderRenderer = () => {
    const customHeaders = [
      () => <span className={styles.rowHeader}>{"Username"}</span>,
      () => <span className={styles.rowHeader}>{"Capacity"}</span>,
      () => <span className={styles.rowHeader}>{"Monthly Rent Fee"}</span>,
      () => <span className={styles.rowHeader}>{"Status"}</span>,
      () => <span className={styles.rowHeader}>{"Renew Date"}</span>,
      () => <span className={styles.rowHeader}>{"Display Number CLI"}</span>,
    ];

    isDIDEnabled &&
      customHeaders.push(() => (
        <SkyButton
          size={ButtonSize.FIT_CONTENT}
          onClick={() =>
            navigate(
              subMenuItemList.filter(
                (item) => item.title === SubMenu.PURCHASE_VIRTUAL_NUMBER
              )[0].route
            )
          }
          testId="buy-number-button"
          renderer={() => (
            <div className={styles.purchaseNumberBtn}>
              <AddIcon />
              <span> Buy Number </span>
            </div>
          )}
        />
      ));
    return customHeaders;
  };

  const fetchVirtualNumbers = () => {
    fetchVirtualNumberList(accessToken ? accessToken : "")
      .then((virtualNumbersResponses: VirtualNumbersResponse[]) => {
        setVirtualNumberList(virtualNumbersResponses);
        setIsLoading(false);
      })
      .catch(() => {
        notyf.error("Unable to fetch virtual number list");
      });
  };

  useEffect(() => {
    fetchVirtualNumbers();
  }, [accessToken]);

  const getRenewDate = () => {
    const currentDate = new Date();
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    return `${nextMonth.getDate()}-${
      nextMonth.getMonth() + 1
    }-${nextMonth.getFullYear()}`;
  };

  const tableCellRenderer = () => {
    return virtualNumberList
      .sort((r1, r2) => r1.lockType - r2.lockType)
      .map((response) => {
        let virtualNumberRows = [
          () => <>{response.username}</>,
          () => <>{response.lineCapacity}</>,
          () => <>{response.monthlyRentFee}</>,
          () => (
            <>
              <div
                data-testid="log-list"
                className={classNames(
                  styles.dot,
                  response.lockType == 0 ? styles.green : styles.red
                )}
              />
            </>
          ),
          () => <>{getRenewDate()}</>,
          () => <CLIDetailsContainer cliDetails={response.cliDetails} />,
        ];

        isDIDEnabled &&
          virtualNumberRows.push(() => (
            <SkyTooltip
              tooltipText={
                response.redirectionInfo != null
                  ? "Update the existing redirection for this virtual number"
                  : "Set up a new extension for this virtual number"
              }
            >
                        <span
                          className={classNames(response.redirectionInfo != null ? styles.redirectGreenIcon : styles.redirectBlueIcon)}
                          data-testid="redirect-number-icon"
                          onClick={() => {
                            setSelectedVirtualNumber(response.username);
                            setIsRedirectDialogOpen(true);
                          }}
                        >
                            <SwapCallsOutlined/>
                        </span>
            </SkyTooltip>
          ));

        return virtualNumberRows;
      });
  };

  return (
    <div
      className={styles.virtualNumberContainer}
      data-testid="virtual-number-list"
    >
      <SkyGrid
        isLoading={isLoading}
        headerRenderer={getCustomHeaderRenderer()}
        renderer={tableCellRenderer()}
        colorBarSpan={6}
        hideColumns = {[2,5]}
      />
      {isRedirectDialogOpen && (
        <Dialog
          testId="redirect-dialog"
          size={Size.SM}
          header={`Redirect Virtual Number : ${selectedVirtualNumber}`}
          overflow={"visible"}
          renderer={() => (
            <RedirectVirtualNumberForm
              virtualNumber={selectedVirtualNumber}
              redirectionInfo={
                virtualNumberList.find(vn => vn.username === selectedVirtualNumber)?.redirectionInfo}
              onCancel={() => setIsRedirectDialogOpen(false)}
              onSuccess={() => {
                setIsRedirectDialogOpen(false);
                fetchVirtualNumbers()
              }}
            />
          )}
        />
      )}
    </div>
  );
};

export default VirtualNumberList;
