import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./InvoiceView.module.css";
import {getInvoiceByInvoiceId} from "../../../api/endpoint";
import {notyf} from "../../../notyf";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import DotLoader from "../../../components/business/DotLoader/DotLoader";
import PdfViewer from "../PdfViewer/PdfViewer";
import SkyButton, {ButtonSize, ButtonType} from "../../../components/base/SkyButton/SkyButton";
import {decodeBase64ToBlob} from "../../../domains/decodeBase64ToBlob";

type Props = {
  invoiceId: string;
  onClose: () => void;
}

const InvoiceView = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const {accessToken} = useContext(AuthContext);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!accessToken || !props.invoiceId || hasFetched.current) return;
    hasFetched.current = true;

    setIsLoading(true);
    getInvoiceByInvoiceId(accessToken, props.invoiceId)
      .then(pdf => {
        const blob = decodeBase64ToBlob(pdf);
        const url = window.URL.createObjectURL(blob);

        setPdfUrl(url);
      })
      .catch(() => {
        notyf.error("Error while fetching Invoice data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessToken, props.invoiceId]);

  return (
    <div className={styles.invoiceViewContainer} data-testid={"invoice-view-container"}>
      {isLoading ? (
        <div className={styles.dotContainer}>
          <DotLoader/>
        </div>
      ) : (
        <div className={styles.pdfContainer}>
          <div className={styles.pdfViewer}>
            <PdfViewer url={pdfUrl}/>
          </div>
          <div className={styles.button}>
            <SkyButton
              size={ButtonSize.SMALL}
              type={ButtonType.CANCEL}
              onClick={() => props.onClose()}
              text={"Close"}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default InvoiceView
